import { FC, useEffect, useState } from 'react';
import { buildTitleString } from '../table/table.component';
import { generateHistoricalTimeSeriesData } from '../data-view.data';
import { AdaptedYAxis } from '../../../../../../../types/components/charts/lineCharts';
import GenericLineChart from '../../../../../../charts/GenericLineChart';
import { DataObject } from '../../../../../../../types/redux/data/dataTypes';
import { ValuesType } from '../../controlBar/controlBar.component';
import { Button, Tooltip, Typography } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import LineAxisIcon from '@mui/icons-material/LineAxis';

export interface HistoricalLineChartProps {
  dimensions: { height: number; width: number };
  height: number;
  inputData: DataObject;
  datesAndKeys: ValuesType;
  fourShowing: boolean;
}
const chartColors = ['red', 'green'];

const HistoricalLineChart: FC<HistoricalLineChartProps> = (props) => {
  const chartData = generateHistoricalTimeSeriesData(props.inputData.data);
  // Function to determine if multiple Y-axes are required

  const determineMultipleYAxes = (): boolean => {
    const keys = chartData.length > 0 ? Object.keys(chartData[0]) : [];
    if (keys.length > 2) {
      const key1Values = chartData.map((element: any) => element[keys[1]]);
      const key2Values = chartData.map((element: any) => element[keys[2]]);
      const key1Max = key1Values.reduce((a: number, b: number) =>
        Math.max(a, b),
      );
      const key1Min = key1Values.reduce((a: number, b: number) =>
        Math.min(a, b),
      );
      const key2Max = key2Values.reduce((a: number, b: number) =>
        Math.max(a, b),
      );
      const key2Min = key2Values.reduce((a: number, b: number) =>
        Math.min(a, b),
      );
      return (
        Math.abs(key1Max - key1Min - (key1Max - key1Min)) > 10 ||
        Math.abs(key1Max - key2Max) > 10 ||
        Math.abs(key1Min - key2Min) > 10
      );
    }
    return false;
  };

  useEffect(() => {
    setShowMultipleYAxes(determineMultipleYAxes);
  }, [props.inputData.data]);

  // Set initial state using the determineMultipleYAxes function
  const [showMultipleYAxes, setShowMultipleYAxes] = useState(
    determineMultipleYAxes,
  );

  // Always use the state to determine whether to show multiple Y-axes
  const multipleYAxes = showMultipleYAxes;

  const lines = props.inputData.data.length
    ? props.inputData.data[0][0]
        .map((column: any, index: number) => {
          if (index === 0) {
            return null;
          }
          return {
            dataKey: column,
            color: chartColors[index - 1],
            yAxisId: multipleYAxes ? (index === 1 ? 'left' : 'right') : 'left',
          };
        })
        .filter((el: any) => Boolean(el))
    : [];

  const additionalYAxis: AdaptedYAxis[] | undefined =
    lines.length > 1 && multipleYAxes
      ? lines
          .map((line: any, index: number) => {
            if (index > 0) {
              return {
                leftDataKey: line.dataKey,
                orientation: 'right',
                dataKey: line.dataKey,
                yAxisId: 'right',
              };
            } else {
              return null;
            }
          })
          .filter((el: any) => Boolean(el))
      : undefined;

  return lines.length ? (
    <>
      <div
        style={{
          padding: '1rem',
          minHeight: 56,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          gap: '10rem',
        }}
      >
        <Typography
          variant="h2"
          style={{ fontWeight: 300, textAlign: 'center' }}
        >
          {buildTitleString(props.datesAndKeys)}
        </Typography>
        <Tooltip
          placement="top"
          title="Toggle the number of Y-axes on the chart"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowMultipleYAxes((prev) => !prev)}
          >
            <span style={{ marginRight: '.5rem' }}>
              {multipleYAxes ? '2  ' : '1  '}
            </span>{' '}
            {multipleYAxes ? <LineAxisIcon /> : <TimelineIcon />}
          </Button>
        </Tooltip>
      </div>
      <GenericLineChart
        title={buildTitleString(props.datesAndKeys)}
        height={props.height}
        width={'100%'}
        data={chartData}
        lines={lines}
        margin={{ top: 0, bottom: 30, left: 50, right: 50 }}
        error={props.inputData.error}
        additionalYAxis={additionalYAxis}
      />
    </>
  ) : null;
};

export default HistoricalLineChart;
